ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  background: none;
  border: none;
  font: inherit;
  color: inherit;
}

/* NORMAL STYLES */
.header {
  position: fixed;
  z-index: 100;
  width: 100%;
}

.top-bar {
  background-color: rgb(39, 44, 51);
}

.top-bar__content {
  height: 30px;
  max-width: 1200px;
  padding: 0 30px;
  margin: 0 auto;
  display: flex;
  justify-content: end;
  align-items: center;
  column-gap: 20px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.35);
}

.icon {
  margin-right: 3px;
  color: rgba(255, 255, 255, 0.17);
}

.bottom-bar {
  background-color: #fff;
  box-shadow: 0px 0px 20px #888;
}

.bottom-bar__content {
  min-height: 60px;
  max-width: 1200px;
  padding: 0 30px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  vertical-align: middle;
  display: flex;
  column-gap: 10px;
  align-items: center;
}

.logo__img {
  height: 70px;
  margin: 10px;
}

.logo__text {
  font-size: 20px;
  color: #fff;
  font-weight: 500;
  letter-spacing: -0.5px;
}

.nav {
  transition: all 0.3s ease-in-out;
}

.nav__list {
  display: flex;
  column-gap: 40px;
}

.nav__item {
}

.nav__link {
  color: #000;
  transition: all 0.2s;
  text-transform: uppercase;
  font-family: "Prompt", sans-serif !important;
}

.nav__link:hover,
.nav__link:focus {
  color: #26387d;
}

.btn {
  color: #fff;
  background-color: #0071e3;
  padding: 8px 20px;
  border-radius: 1000px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.btn:hover {
  background-color: rgb(28, 128, 228);
}

.hamburger {
  cursor: pointer;
  display: none;
}

.bar {
  height: 2px;
  width: 27px;
  background-color: #000000;
  margin: 5px 0;
  opacity: 0.8;
  transition: all 0.3s ease-in-out;
}
/* Add this CSS to your Navbar.css */
.dropdown-menu {
  position: absolute;
  margin: 0px 0px 0px 0px;
  display: none;
  background-color: #fff;
  padding: 10px 0px;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.nav__item:hover .dropdown-menu {
  display: block;
  font-size: 16px;
  font-family: "Prompt", sans-serif !important;
  color: #000;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: "Prompt", sans-serif !important;
}

.dropdown-menu__item {
  padding: 5px 20px;
  font-weight: bold;
  color: #000 !important;
  font-family: "Courier New", Courier, monospace !important;
}
.Stef {
  color: #000;
  font-weight: 600;
}
/* For JS */
.nav--open {
  left: 50% !important;
}

.hamburger--open .bar:nth-child(1) {
  transform: translateY(7px) rotate(45deg);
}

.hamburger--open .bar:nth-child(2) {
  opacity: 0;
}

.hamburger--open .bar:nth-child(3) {
  transform: translateY(-7px) rotate(-45deg);
}
.top-details {
  font-size: 15px;
  font-family: "Work Sans", sans-serif;
  padding: 0px;
  color: gray;
  font-weight: 800;
}
.dropdown-menu__list_Responsive {
  display: none !important;
}
/* MEDIA QUERIES */
@media (max-width: 650px) {
  .nav {
    position: fixed;
    top: 90px;
    left: -100%;
    transform: translateX(-50%);
    background-color: #fff;
    width: 100%;
    padding: 10px 0 25px;
  }

  .nav__list {
    flex-direction: column;
    row-gap: 13px;
    padding: 0px 20px;
  }

  .nav__link {
    font-size: 14px;
  }

  .btn {
    font-size: 11px;
    padding: 7px 17px;
  }

  .hamburger {
    display: block;
  }
  .dropdown-menu__list_Responsive {
    display: block !important;
  }
}

@media (max-width: 360px) {
  .top-bar__content {
    font-size: 10px;
  }
}
@media all and (max-width: 600px) {
  .top-details {
    font-size: 10px;
  }
}
