.Dammy {
  width: 100%;
  height: 120px;
  background-color: rgb(255, 255, 255);
}

.Banner-img {
  width: 100%;
  height: 550px;
  margin: 0 auto;
  background-image: url(../IMG/Banner.svg);
  background-size: 100% auto;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
}

.Contenr {
  padding: 8rem 0;
  position: absolute;
  text-align: center;
}

.BanText {
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  color: #000;
  margin-bottom: 20px;
}

.BanHedText {
  font-size: 50px;
  color: #fff;
  font-weight: 900;
  line-height: 55px;
  width: 80%;
  margin: 0 auto;
  text-transform: capitalize;
  font-family: Prompt, sans-serif !important;
}

.Btn-Ban {
  font-size: 20px;
  background: #0097df;
  background: linear-gradient(
    90deg,
    rgba(0, 151, 223, 1) 0,
    rgba(32, 63, 129, 1) 50%,
    rgba(45, 48, 121, 1) 100%
  );
  padding: 10px 20px;
  color: #fff;
  border-radius: 8px;
  text-transform: uppercase;
  margin-top: 1rem;
  cursor: pointer;
}

.Btn-Ban:hover {
  background: #2d3079;
  background: linear-gradient(
    90deg,
    rgba(45, 48, 121, 1) 0,
    rgba(32, 63, 129, 1) 50%,
    rgba(0, 101, 149, 1) 100%
  );
}

.Layout-Con {
  width: 80%;
  height: auto;
  max-width: 1200px;
  display: flex;
  margin: 2rem auto;
}

.Lay-Img {
  width: 50%;
}

.Lay-Cont {
  width: 50%;
}

.Img-l {
  height: 400px;
}

.Lay-canrte {
  font-size: 18px;
  text-transform: uppercase;
  color: #26387d;
  font-weight: 600;
  font-family: Prompt, sans-serif !important;
}

.Lay-Teke {
  font-size: 35px;
  text-transform: uppercase;
  color: #000;
  font-weight: bolder;
  margin: 20px 0;
}

.Lay-sub {
  color: #5a5a5a;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 20px;
  margin: 10px 0;
}

.service-contener {
  text-align: center;
  margin: 60px 0 0 0;
}

.Set-lay {
  font-size: 18px;
  text-transform: uppercase;
  color: #26387d;
  font-weight: 600;
  text-align: center;
  font-family: Prompt, sans-serif !important;
}

.teg-line {
  font-size: 35px;
  text-transform: uppercase;
  color: #000;
  font-weight: bolder;
  margin: 20px 0;
}

.Btn-Ban2 {
  font-size: 16px;
  background: #0097df;
  background: linear-gradient(
    90deg,
    rgba(0, 151, 223, 1) 0,
    rgba(32, 63, 129, 1) 50%,
    rgba(45, 48, 121, 1) 100%
  );
  padding: 10px 20px;
  color: #fff;
  border-radius: 8px;
  text-transform: uppercase;
  margin-top: 1rem;
  cursor: pointer;
}

.Btn-Ban2:hover {
  background: #2d3079;
  background: linear-gradient(
    90deg,
    rgba(45, 48, 121, 1) 0,
    rgba(32, 63, 129, 1) 50%,
    rgba(0, 101, 149, 1) 100%
  );
}
.Services-cards {
  width: 80%;
  max-width: 1300px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: space-between;
}
.Ser-card {
  width: 32%;
  box-shadow: 0 2px 20px #e2e2e2;
  border-radius: 8px;
  padding: 10px 10px;
  margin: 10px 0px;
}
.Ser-Img90 {
  width: 100%;
  border-radius: 8px;
}
.Ser-head {
  font-size: 20px;
  text-align: left;
  margin: 10px 0px;
  font-family: "Prompt", sans-serif !important;
  line-height: 20px;
}
.Ser-Description {
  font-family: sans-serif;
  font-size: 15px;
  text-align: left;
  margin-bottom: 20px;
  color: #666666;
  text-transform: capitalize;
  line-height: 22px;
}

.Btn-Services {
  background-color: #26387d;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  display: flex;
  padding: 5px 15px;
  border-radius: 8px;
  margin-top: 10px;
  text-align: left;
  margin-bottom: 10px;
}
@media all and (max-width: 1200px) {
  .Layout-Con {
    width: 95%;
  }
  .Services-cards {
    width: 90%;
  }
}

@media all and (max-width: 1100px) {
  .Banner-img {
    height: 400px;
  }

  .Contenr {
    padding: 3rem 0;
  }

  .BanText {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .BanHedText {
    font-size: 40px;
    line-height: 43px;
  }

  .Btn-Ban {
    font-size: 13px;
  }
}

@media all and (max-width: 950px) {
  .BanHedText {
    width: 100%;
  }

  .Lay-canrte {
    font-size: 13px;
  }

  .Lay-Teke {
    font-size: 27px;
  }

  .Lay-sub {
    font-size: 13px;
  }

  .Btn-Ban {
    font-size: 11px;
  }

  .Set-lay {
    font-size: 14px;
  }

  .teg-line {
    font-size: 25px;
  }

  .Btn-Ban2 {
    font-size: 14px;
  }
  .Services-cards {
    width: 98%;
  }
}

@media all and (max-width: 900px) {
  .Layout-Con {
    display: block;
    width: 95%;
  }

  .Lay-Cont {
    width: 100%;
  }

  .Lay-Img {
    width: 100%;
    display: flex;
    margin: 0 auto;
    justify-content: center;
  }

  .teg-line {
    margin: 10px 0 30px 0;
    font-size: 20px;
  }

  .Set-lay {
    font-size: 10px;
  }
}

@media all and (max-width: 800px) {
  .Banner-img {
    height: 300px;
  }

  .BanText {
    font-size: 13px;
  }

  .BanHedText {
    font-size: 28px;
    line-height: 32px;
  }

  .Lay-Teke {
    font-size: 20px;
  }
  .Ser-head {
    font-size: 18px;
  }
  .Ser-Description {
    font-size: 13px;
  }
  .Btn-Services {
    font-size: 14px;
    padding: 7px 12px;
    margin-bottom: 0px;
  }
}

@media all and (max-width: 650px) {
  .Banner-img {
    height: 220px;
  }

  .Contenr {
    padding: 2rem 0;
  }

  .BanText {
    font-size: 11px;
  }

  .BanHedText {
    font-size: 21px;
    line-height: 25px;
  }

  .Btn-Ban {
    font-size: 9px;
    padding: 8px 14px;
    border-radius: 4px;
  }
  .Ser-card {
    width: 45%;
  }
  .Services-cards {
    width: 95%;
    justify-content: space-around;
  }
}
@media all and (max-width: 500px) {
  .Ser-card {
    width: 90%;
  }
}

@media all and (max-width: 470px) {
  .Banner-img {
    height: 180px;
  }

  .Img-l {
    height: 270px;
    margin-bottom: 20px;
  }

  .Lay-Cont {
    margin: 0 auto;
    width: 90%;
  }

  .service-contener {
    text-align: center;
    margin: 0;
  }

  .Btn-Ban2 {
    font-size: 12px;
    padding: 9px 15px;
    border-radius: 4px;
  }
}
