.Main-Blogs {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 1rem auto;
}
.Blog {
  margin: 1.5%;
  width: 30%;
  border-bottom: 2px solid #000;
}
.Blog-img {
  width: 100%;
  border-radius: 4px;
  height: 220px;
  background-size: contain;
  object-fit: fill;
}
.Blog-date {
  font-family: sans-serif;
  color: #495057;
  font-size: 15px;
  text-transform: uppercase;
  padding: 10px 0px;
}
.Blog-head {
  font-family: "Prompt", sans-serif !important;
  font-size: 23px;
  line-height: 23px;
  padding: 10px 0px;
  height: 70px;
  overflow: hidden;
}
.Blog-Content {
  font-size: 16px;
  font-family: sans-serif;
  height: 80px;
  padding: 10px 0px;
  color: #495057;
  overflow: hidden;
  line-height: 20px;
}
@media all and (max-width: 1000px) {
  .Blog {
    width: 45%;
  }
  .Main-Blogs {
    justify-content: space-between;
  }
  .Blog-head {
    line-height: 30px;
  }
  .Blog {
    width: 96%;
  }
}
@media all and (max-width: 630px) {
  .Blog {
    width: 96%;
  }
}
