.Banner-all {
  width: 100%;
  height: 150px;
  text-align: center;
  background-image: url(./Img/ban.png);
  display: flex;
  align-items: center;
  justify-content: center;
}
.Ba-ab-set {
  color: #fff;
  text-align: center;
  font-family: "Prompt", sans-serif !important;
}
.About-top {
  display: flex;
  width: 90%;
  max-width: 1200px;
  margin: 3rem auto 1rem auto;
  align-items: center;
}
.Top-Main {
  width: 50%;
}
.img-top {
  width: 90%;
  height: 400px;
  background-size: contain;
  object-fit: fill;
  margin: 0 auto;
}
.Tetx-ab {
  font-size: 35px;
  margin: 20px 0px;
  font-family: "Prompt", sans-serif !important;
}
.Ab-content {
  color: #000;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 20px;
}

.Black-ab {
  width: 100%;
  height: 300px;
  margin-top: 15rem;
  background-color: #000;
  position: relative;
  display: flex;
  justify-content: center;
}
.add-section {
  width: 90%;
  display: flex;
  margin: 1rem auto;
  max-width: 1200px;
}
.Set-douy1 {
  width: 50%;
}
.JS028 {
  width: 90%;
  padding: 20px;
  height: 380px;
}
.Set-douy2 {
  width: 50%;
  padding: 20px 0px;
}
.Shjgf37 {
  font-family: "Prompt", sans-serif !important;
  font-size: 25px;
  line-height: 33px;
  margin: 10px 5px;
}
.Add-desrciption {
  color: #000;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 20px;
}
.setup-ab {
  width: 85%;
  max-width: 1200px;
  position: absolute;
  margin: -130px auto 0 auto;
  background-color: #ffffff;
  display: flex;
  padding: 20px 50px;
}
.Ab-oin {
  width: 50%;
}
.Ab-oin2 {
  width: 50%;
  display: flex;
  justify-content: right;
}
.Set-img-ab {
  width: 300px;
  height: 200px;
}
.sert752 {
  font-size: 14px;
  color: #000;
  font-weight: 600;
  text-transform: uppercase;
}
.Mainten-ab {
  font-size: 35px;
  color: #26387d;
  font-weight: 900;
  font-family: "Prompt", sans-serif !important;
  font-family: "Work Sans", sans-serif;
}
.ab-subline {
  font-size: 14px;
  font-weight: 600;
  margin: 10px 0px;
  line-height: 20px;
  letter-spacing: 1px;
}
.Btn-Ban-ab {
  font-size: 16px;
  background: rgb(0, 151, 223);
  background: linear-gradient(
    90deg,
    rgba(0, 151, 223, 1) 0%,
    rgba(32, 63, 129, 1) 50%,
    rgba(45, 48, 121, 1) 100%
  );
  padding: 10px 20px;
  color: #fff;
  border-radius: 8px;
  margin-top: 1rem;
  cursor: pointer;
}
.Btn-Ban-ab:hover {
  background: rgb(45, 48, 121);
  background: linear-gradient(
    90deg,
    rgba(45, 48, 121, 1) 0%,
    rgba(32, 63, 129, 1) 50%,
    rgba(0, 101, 149, 1) 100%
  );
}
.Steap-min {
  width: 90%;
  margin: 1rem auto;
  max-width: 1200px;
}
.Main-sub-text {
  font-size: 16px;
  color: #000;
  letter-spacing: 1px;
  line-height: 20px;
  padding: 0px 250px 0px 0px;
}
.Step-contenters {
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: space-between;
}
.Steps-type {
  width: 47%;
  /* height: 120px;        */
  background-color: rgb(255, 255, 255);
  margin: 20px 0px;
  display: flex;
  border-radius: 10px;
  box-shadow: 1px 0px 20px 0px #888888;
  align-items: center;
}
.img-setp {
  width: 30%;
}
.Contener-setp {
  width: 70%;
}
.Img-Setaf61 {
  height: 130px;
  margin: 15px;
  width: 130px;
}
.Setups082 {
  color: #26387d;
  font-size: 20px;
  margin: 5px 10px;
}
.Setp-cont {
  color: #000;
  font-size: 15px;
  margin: 5px 10px;
  line-height: 20px;
}
@media all and (max-width: 1300px) {
  .Steps-type {
    width: 49%;
  }
}
@media all and (max-width: 1200px) {
  .Setups082 {
    font-size: 16px;
    margin: 5px 10px;
  }
  .Setp-cont {
    color: #000;
    font-size: 14px;
    margin: 5px 10px;
    line-height: 17px;
  }
  .Img-Setaf61 {
    height: 110px;
    margin: 10px;
    width: 110px;
  }
  .counter {
    font-size: 17px;
  }
  .counter-text {
    font-size: 17px;
  }
  .Nit-counter {
    font-size: 30px;
  }
}
@media all and (max-width: 1060px) {
  .Steap-min {
    width: 95%;
  }
  .Steps-type {
    width: 55%;
  }
  .Step-contenters {
    justify-content: center;
  }
  .img-top {
    height: 350px;
  }
  .Tetx-ab {
    font-size: 30px;
    margin: 20px 0px;
  }
  .Ab-content {
    font-size: 14px;
    line-height: 19px;
  }
  .Mainten-ab {
    font-size: 25px;
    text-align: center;
  }
  .Main-sub-text {
    line-height: 20px;
    text-align: center;
    padding: 0;
  }
  .sert752 {
    font-size: 11px;
  }
  .ab-subline {
    font-size: 11px;
  }
  .Btn-Ban-ab {
    font-size: 9px;
    padding: 7px 14px;
    border-radius: 4px;
  }
  .Ab-oin {
    width: 65%;
  }
  .setup-ab {
    padding: 20px 20px;
  }
  .Set-img-ab {
    width: 260px;
    height: 170px;
  }
  .add-section {
    width: 95%;
  }
}
@media all and (max-width: 900px) {
  .About-top {
    width: 95%;
  }

  .Tetx-ab {
    font-size: 25px;
    margin: 10px 0px;
  }
  .Counter-Data {
    width: 95%;
  }
}
@media all and (max-width: 800px) {
  .Nit-counter {
    font-size: 25px;
  }
  .Counter-Data {
    margin: 0 auto 1rem auto;
    margin-top: 10px;
  }
  .Shjgf37 {
    font-family: "Prompt", sans-serif !important;
    font-size: 20px;
    line-height: 27px;
  }
  .Add-desrciption {
    font-size: 14px;
    line-height: 18px;
  }
}
@media all and (max-width: 750px) {
  .Steps-type {
    width: 75%;
  }
  .Tetx-ab {
    font-size: 20px;
    margin: 10px 0px;
  }
  .About-top {
    width: 95%;
    margin: 1rem auto 1rem auto;
    display: block;
  }
  .Top-Main {
    width: 100%;
  }
  .img-top {
    height: 350px;
    width: 100%;
  }
  .Ab-oin2 {
    display: none;
  }
  .Ab-oin {
    width: 95%;
  }
  .Black-ab {
    margin-top: 9rem;
  }
  .Black-ab {
    height: 200px;
  }
  .add-section {
    display: block;
  }
  .Set-douy1 {
    width: 100%;
  }
  .JS028 {
    width: 90%;
    margin: 0 auto;
    height: 380px;
    display: flex;
  }
  .Set-douy2 {
    width: 90%;
    margin: 0 auto;
    padding: 20px 0px;
  }
}
@media all and (max-width: 550px) {
  .Steps-type {
    width: 90%;
  }
  .setup-ab {
    padding: 10px;
  }
  .Mainten-ab {
    font-size: 20px;
  }
  .ab-subline {
    font-size: 10px;
    line-height: 15px;
  }
  .JS028 {
    width: 90%;
    margin: 0 auto;
    padding: 10px;
    height: 270px;
    display: flex;
  }
}
@media all and (max-width: 450px) {
  .Setups082 {
    font-size: 14px;
    margin: 5px 10px;
  }
  .Setp-cont {
    font-size: 11px;
    line-height: 13px;
  }
  .Img-Setaf61 {
    height: 90px;
    margin: 10px;
    width: 90px;
  }
  .JS028 {
    width: 100%;
  }
  .Set-douy2 {
    width: 100%;
  }
}
