.Contact-hedding {
  color: #26387d;
  font-size: 35px;
  text-align: center;
  margin: 1rem 0 0.2rem 0;
  font-family: "Prompt", sans-serif !important;
}
.C-description {
  text-align: center;
  color: #000;
  font-size: 18px;
  font-family: sans-serif;
  letter-spacing: 1px;
}
.Contect-Details {
  width: 90%;
  display: flex;
  max-width: 1200px;
  margin: 4rem auto;
  border-radius: 4px;
  box-shadow: -1px -1px 20px #dadada;
}
.Details {
  width: 40%;
  background-color: #26387d;
  border-radius: 4px;
  padding: 20px 30px;
}
.Detail-con {
  color: #fff;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 1px;
  font-family: "Prompt", sans-serif !important;
}
.Sey-fetr {
  color: #dddddd;
  font-size: 16px;
  letter-spacing: 1px;
  font-family: sans-serif;
}
.Conection-data {
  display: block;
  margin-top: 60px;
}
.Set-number {
  width: 100%;
  margin: 20px 0px;
  display: flex;
  align-items: center;
}
.iCon {
  width: 10%;
  color: #fff;
  font-size: 22px;
}
.Conctign {
  width: 90%;
  color: #fff;
  font-size: 16px;
}
.Form-Fillling {
  width: 60%;
  border-radius: 4px;
  padding: 20px 40px;
}
.Fill-data {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.From {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.input-fildw {
  width: 49%;
  display: block;
  margin: 10px 0px;
}
.input-fildw1 {
  width: 98%;
  display: block;
  margin: 10px 0px;
}
.input-fildw1 {
  width: 100%;
  display: block;
  margin: 10px 0px;
}
.fild-tezt {
  font-size: 16px;
  color: #8d8d8d;
  margin: 10px 0px;
  font-weight: 600;
}
.Input-wch {
  width: 95%;
  outline: none;
  border: none;
  border-bottom: 1px solid #8d8d8d;
}
.Input-wch option {
  color: #8d8d8d;
  font-size: 16px;
  padding: 10px 0px;
}
@media all and (max-width: 950px) {
  .Contect-Details {
    margin: 2rem auto;
    display: block;
  }
  .Details {
    width: 100%;
  }
  .Form-Fillling {
    width: 100%;
  }
  .Conection-data {
    margin-top: 30px;
  }
}
@media all and (max-width: 550px) {
  .Details {
    padding: 20px 10px;
  }
  .fild-tezt {
    font-size: 12px;
  }
}
