.Set-Counter {
  width: 100%;
}
.Nit-counter {
  text-align: center;
  font-size: 40px;
  color: #26387d;
  font-family: "Prompt", sans-serif !important;
  font-weight: 900;
}
.Counter-Data {
  width: 80%;
  max-width: 1200px;
  margin: 0 auto 4rem auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 30px;
}
.Box-counter {
  width: 25%;
  display: block;
  text-align: center;
}
.counter {
  color: #26387d;
  font-size: 20px;
  font-family: "Prompt", sans-serif !important;
  font-weight: 900;
}
.Set-Nice {
  display: flex;
  justify-content: center;
  align-items: center;
}
.counter-text {
  color: #000;
  font-size: 20px;
  /* font-family: "Prompt", sans-serif !important; */
  font-weight: 600;
}
@media all and (max-width: 1100px) {
  .Nit-counter {
    font-size: 30px;
  }
  .counter-text {
    font-size: 15px;
  }
  .counter {
    font-size: 16px;
  }
  .Counter-Data {
    width: 95%;
  }
}
@media all and (max-width: 750px) {
  .Box-counter {
    width: 50%;
    margin: 10px 0px;
  }
}
