* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Work Sans", sans-serif;
}

.New-Color {
  color: #26387d;
  font-family: "Prompt", sans-serif !important;

}
p{
  margin-bottom: 0 !important;
}
a{
  text-decoration: none !important;
}