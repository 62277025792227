.Testimonials {
  width: 80%;
  margin: 0 auto;
  max-width: 1200px;
  margin-top: 40px;
}
.teti-text {
  color: #26377d;
  font-size: 16px;
  text-align: center;
}
.Textim-text {
  color: #000;
  font-size: 40px;
  line-height: 40px;
  font-weight: bolder;
  text-align: center;
  font-family: "Prompt", sans-serif !important;
}
.videoTag {
  width: 100%;
  height: 500px;
  margin: 20px 0px;
  background-size: contain;
  object-fit: cover;
}
.Test-Boundary {
  width: 80%;
  height: auto;
  margin: 2rem auto 4rem auto;
  text-align: center;
  max-width: 1200px;
}
.slider2 {
  margin-top: 4rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.Client-slider {
  width: 90% !important;
}
.Main-Content {
  font-size: 16px;
  color: #5a5a5a;
  letter-spacing: 1px;
  line-height: 19px;
  font-weight: 600;
  margin-top: 0.5rem;
}
.Clinet-name {
  font-size: 20px;
  color: #5a5a5a;
  letter-spacing: 1px;
  line-height: 19px;
  font-weight: 600;
  margin-top: 0rem;
}
.Reting {
  width: 80%;
  display: flex;
  justify-content: center;
  margin: 1rem auto;
}
.Client {
  height: 60px;
  width: 60px;
  margin: 1rem auto;
}
.Deal-promi {
  margin-top: 5rem;
  width: 100%;
  background: rgb(0, 151, 223);
  background: linear-gradient(
    90deg,
    rgba(0, 151, 223, 1) 0%,
    rgba(32, 63, 129, 1) 50%,
    rgba(45, 48, 121, 1) 100%
  );
}
.Deal-short {
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  padding: 20px 0px;
}
.Main-text8 {
  width: 80%;
  display: flex;
  align-items: center;
}
.Btns {
  width: 20%;
}
.Main-te {
  font-size: 30px;
  padding: 10px 20px;
  color: #fff;
  text-transform: capitalize;
}
.brtncon {
  font-size: 16px;
  background: #fff;
  padding: 10px 20px;
  color: #26387d;
  border-radius: 8px;
  text-transform: uppercase;
  margin: 1rem;
  cursor: pointer;
}
@media all and (max-width: 1200px) {
  .Textim-text {
    font-size: 30px;
  }
  .teti-text {
    font-size: 15px;
  }
  .Test-Boundary {
    width: 90%;
  }
  .Testimonials {
    width: 95%;
  }
}
@media all and (max-width: 900px) {
  .Textim-text {
    font-size: 25px;
  }
  .teti-text {
    font-size: 14px;
    font-weight: 800;
  }
  .videoTag {
    height: 390px;
  }
  .Main-Content {
    font-size: 11px;
    line-height: 15px;
  }
  .Client {
    height: 40px;
    width: 40px;
  }
  .Reting {
    font-size: 10px;
  }
  .Clinet-name {
    font-size: 13px;
  }
  .Designation {
    font-size: 12px;
  }
  .slider2 {
    margin-top: 2rem;
  }
}
@media all and (max-width: 700px) {
  .videoTag {
    height: 300px;
  }
}
@media all and (max-width: 600px) {
  .Textim-text {
    font-size: 20px;
  }
  .teti-text {
    font-size: 10px;
    font-weight: 800;
  }
  .videoTag {
    height: 240px;
  }
}
