.Servic-Main {
  width: 100%;
  max-width: 1280px;
  margin: 2rem auto 0 auto;
}
.Ser-Mitext {
  font-size: 35px;
  text-transform: capitalize;
  text-align: center;
  font-family: "Prompt", sans-serif !important;
}
.span-tag {
  color: #26377d;
  font-family: "Prompt", sans-serif !important;
}
.Text-img-ser {
  width: 80%;
  height: auto;
  margin: 2rem auto 5rem auto;
  display: flex;
  max-width: 1200px;
}
.SText-contaner {
  width: 50%;
  height: auto;
  align-items: center;
}
.SImg-con {
  width: 50%;
  display: flex;
  margin: 0 auto;
  justify-content: center;
}
.setup-text {
  font-size: 25px;
  line-height: 30px;
  margin: 30px 0px;
  font-family: "Prompt", sans-serif !important;
}
.Blocl {
  display: block;
}
.Ser-section-aed {
  width: 80%;
  display: flex;
  margin: 1rem auto;
  max-width: 1200px;
}
.Stp098 {
  color: #000;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 23px;
}
.set-checks {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 20px;
}
.Box-check {
  width: 50%;
  display: flex;
  margin: 5px 0px;
  color: #26377d;
  align-items: center;
  font-family: "Prompt", sans-serif !important;
}
.Icon-check {
  color: #26377d;
  font-weight: 700;
  margin: 0px 5px;
}
.imgs-set {
  width: 80%;
  height: 400px;
}
.Sub-heding {
  font-size: 35px;
  text-transform: capitalize;
  text-align: center;
  color: #26377d;
  margin: 0.5rem 0rem;
  font-family: "Prompt", sans-serif !important;
}
.Sub-mintext {
  color: #000;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 23px;
  max-width: 45%;
  text-align: center;
  margin: 0 auto;
}
.Main-Stype {
  max-width: 1200px;
  display: flex;
  width: 80%;
  margin: 2rem auto;
  flex-wrap: wrap;
  justify-content: space-between;
}
.Type-ser {
  width: 24%;
  height: 200px;
}
.Type-img {
  width: 50px;
  height: 50px;
}
.Type-head {
  font-size: 20px;
  margin: 0.5rem 0px;
  font-family: "Prompt", sans-serif !important;
}
.Type-Scale {
  font-size: 15px;
  line-height: 20px;
  margin: 0.5rem 0px;
  color: #000;
}
.Frendly {
  max-width: 1150px;
  margin: 1rem auto;
  padding: 0 50px;
  width: 90%;
  height: 500px;
  background-image: url(./images/Backsystem.svg);
  background-size: contain;
  object-fit: fill;
  background-repeat: no-repeat;
  justify-content: space-between;
}
.Set-Secet {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.TExt-secret {
  width: 50%;
  color: #fff;
  line-height: 33px;
  font-size: 30px;
  margin: 20px 20px;
  font-family: "Prompt", sans-serif !important;
}

.Min-texsecre {
  width: 40%;
  color: #fff;
  font-size: 16px;
  margin: 40px 20px;
}
.Stser982 {
  width: 100%;
  display: flex;
  border-top: 1px solid #4e65b6;
}
.Set-1-ser {
  width: 50%;
}
.Hanekl-seyh {
  display: flex;
  margin: 40px 0px;
  width: 100%;
}
.set-ki092 {
  font-family: "Prompt", sans-serif !important;
  font-size: 18px;
  color: #fff;
}
.set-Lodw3 {
  font-size: 13px;
  color: #fff;
}
.ser-img98 {
  height: 60px;
  width: 60px;
}
.Dk-img {
  width: 25%;
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
}
.DK-secno {
  width: 75%;
  margin: auto;
}
.ST-baneuy875 {
  width: 80%;
  max-width: 1200px;
  margin: 4rem auto;
  display: flex;
  flex-wrap: wrap;
}
.Ban98-img {
  width: 50%;
}
.HDf9826 {
  width: 80%;
  margin: 1.5rem auto;
}
.set-text09 {
  font-family: "Prompt", sans-serif !important;
  font-size: 30px;
  color: #000000;
  margin-top: 2rem;
}
.Line-one {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 20px 0px;
}
.Linumber {
  font-family: "Prompt", sans-serif !important;
  font-size: 40px;
  color: #9ea3b5;
  width: 10%;
}
.LiHedcont {
  font-size: 16px;
  color: #000;
  width: 25%;
  font-weight: bolder;
}
.LINContent {
  width: 65%;
  font-size: 14px;
  color: #545971;
}
.Set-banner-foot {
  background-image: url(./images//ServiceBanner.svg);
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  height: 430px;
  background-size: contain;
  object-fit: fill;
  background-repeat: no-repeat;
}
.Ste-jk65 {
  color: #fff;
  font-size: 35px;
  padding: 120px 0px 0px 50px;
  font-family: "Prompt", sans-serif !important;
  width: 60%;
}
.Seyt752 {
  display: flex;
  width: 50%;
  margin-left: 50px;
  margin-top: 20px;
}
.Ste7265 {
  color: #fff;
  margin: 0 10px;
  font-size: 16px;
  font-weight: 600;
  font-family: "Prompt", sans-serif !important;
}
.Set272 {
  margin: 0px 10px;
}
.Btn-banbac {
  background-color: #fff;
  font-size: 19px;
  padding: 5px 20px;
  margin: 20px 50px;
  border-radius: 10px;
  color: #26377d;
  font-family: "Prompt", sans-serif !important;
}
.Ser-bsu {
  width: 50%;
}
.Set-fjimg {
  height: 500px;
  width: 90%;
}
.setupn-text {
  font-size: 25px;
  line-height: 30px;
  margin: 10px 0px;
  font-family: "Prompt", sans-serif !important;
}
.With-text-area {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 23px;
  display: flex;
  align-items: center;
  width: 100%;
}
.For-addign {
  width: 90%;
}
.Icon-check-ser {
  color: #000;
  margin: 0 5px;
  font-size: 18px;
  width: 10%;
}
@media all and (max-width: 1200px) {
  .TExt-secret {
    line-height: 33px;
    font-size: 25px;
  }
  .Min-texsecre {
    width: 40%;
    color: #fff;
    font-size: 16px;
    margin: 30px 20px;
  }
  .Frendly {
    width: 100%;
    height: auto;
    background-size: cover;
  }
  .setupn-text {
    font-size: 20px;
    line-height: 25px;
  }
  .With-text-area {
    margin: 5px 0px;
    font-size: 12px;
    line-height: 19px;
  }
}
@media all and (max-width: 1250px) {
  .Main-Stype {
    width: 95%;
  }
}
@media all and (max-width: 1150px) {
  .Ser-Mitext {
    font-size: 27px;
    width: 80%;
    margin: 0 auto;
  }
  .Text-img-ser {
    width: 95%;
  }
  .Hanekl-seyh {
    margin: 30px 0px;
  }
  .Min-texsecre {
    display: none;
  }
  .ser-img98 {
    height: 50px;
    width: 50px;
  }
  .set-ki092 {
    font-size: 15px;
  }
  .Dk-img {
    width: 15%;
  }
  .DK-secno {
    width: 85%;
  }
  .TExt-secret {
    margin: 10px 20px;
  }
}
@media all and (max-width: 1050px) {
  .Type-ser {
    width: 45%;
    height: 200px;
  }
  .Sub-mintext {
    font-size: 16px;
    max-width: 85%;
  }
  .set-text09 {
    font-size: 25px;
  }
  .ST-baneuy875 {
    margin: 1rem auto;
    width: 95%;
  }
  .Ste-jk65 {
    font-size: 35px;
    padding: 60px 0px 0px 50px;
    width: 90%;
  }
  .Set-banner-foot {
    height: auto;
  }
}
@media all and (max-width: 920px) {
  .Frendly {
    background-image: none;
    background-color: #26377d;
    padding: 0 10px;
  }
  .Ser-section-aed {
    width: 95%;
  }
}
@media all and (max-width: 850px) {
  .Ser-Mitext {
    font-size: 22px;
    line-height: 26px;
  }
  .setup-text {
    font-size: 20px;
    line-height: 23px;
  }
  .Stp098 {
    font-size: 14px;
    line-height: 21px;
  }
  .imgs-set {
    width: 90%;
    height: 370px;
  }
  .Box-check {
    font-size: 15px;
  }
  .Hanekl-seyh {
    margin: 20px 0px;
  }
  .Hanekl-seyh {
    width: 95%;
    height: 90px;
  }
  .Dk-img {
    width: 20%;
  }
  .TExt-secret {
    width: 80%;
  }
  .ST-baneuy875 {
    display: block;
  }
  .Ban98-img {
    width: 90%;
    margin: 0 auto;
  }
  .HDf9826 {
    display: flex;
  }
  .Linumber {
    font-size: 33px;
  }
  .LiHedcont {
    font-size: 13px;
  }
  .Line-one {
    align-items: flex-start;
  }
  .Set-banner-foot {
    height: auto;
    width: 100%;
    background-size: contain;
    object-fit: fill;
  }
  .Ste-jk65 {
    font-size: 25px;
    padding: 30px 0px 0px 50px;
    width: 90%;
  }
}
@media all and (max-width: 650px) {
  .Text-img-ser {
    width: 95%;
    display: block;
  }
  .SText-contaner {
    width: 100%;
  }
  .SImg-con {
    width: 97%;
  }
  .Sub-heding {
    font-size: 27px;
  }
  .Ste-jk65 {
    font-size: 20px;
    padding: 15px 0px 0px 50px;
  }
  .Set-banner-foot {
    height: 230px;
  }
  .Seyt752 {
    width: 70%;
    margin-left: 30px;
    margin-top: 13px;
  }
  .Btn-banbac {
    font-size: 13px;
    padding: 5px 12px;
    margin: 20px 50px;
    border-radius: 4px;
  }
  .Ser-section-aed {
    width: 95%;
    display: block;
  }
  .Ser-bsu {
    width: 100%;
    margin: 0 auto;
  }
  .Set-fjimg {
    height: 500px;
    width: 100%;
    margin: 0 auto;
  }
}
@media all and (max-width: 550px) {
  .Type-ser {
    width: 49%;
    height: 200px;
    text-align: center;
  }

  .Type-img {
    width: 40px;
    height: 40px;
  }
  .Type-head {
    font-size: 17px;
  }
  .Type-Scale {
    font-size: 14px;
    line-height: 19px;
    margin: 0.5rem 0px;
    color: #000;
  }
  .Stser982 {
    display: block;
  }
  .Set-1-ser {
    width: 100%;
  }
}
@media all and (max-width: 500px) {
  .Ste-jk65 {
    font-size: 15px;
    line-height: 19px;
  }
  .Ste7265 {
    font-size: 10px;
  }
  .Btn-banbac {
    font-size: 9px;
  }
}
@media all and (max-width: 450px) {
  .imgs-set {
    width: 90%;
    height: 345px;
  }
  .Ser-Mitext {
    font-size: 19px;
    width: 87%;
    line-height: 22px;
  }
  .setup-text {
    font-size: 17px;
    margin: 9px 0px;
    line-height: 20px;
  }
  .Stp098 {
    font-size: 13px;
    line-height: 18px;
  }
  .set-checks {
    margin-top: 20px;
  }
  .Type-head {
    font-size: 12px;
  }
  .Type-Scale {
    font-size: 10px;
    line-height: 14px;
    margin: 0.5rem 0px;
    color: #000;
  }
  .Type-ser {
    width: 49%;
    height: 145px;
    text-align: center;
  }
  .Btn-banbac {
    margin: 10px 50px;
  }
  .Set-banner-foot {
    height: 134px;
  }
}
